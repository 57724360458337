import React from "react"

import MfaaLogo from "../../assets/images/careers/mfaa-logo.jpg"
import McgrathOffices from "../../assets/images/careers/graphic-mcgrath-office.svg"
import McgrathSold from "../../assets/images/careers/mcgrath-sold.png"

const LargeReferal = () => {
    return (
            <div className="container">
                <h3 className="h3-careers">Large Referral Base</h3>
                <div className="row d-flex justify-content-center mt-4">

                    <div className="col-11 col-sm-10 col-md-4">
                        <div className="refferal-card highlight-text">
                            <div className="badge-wrapper mb-3">
                                <img className="img-mg-offices" src={McgrathOffices} alt="graphic-mcgrath-office"/>
                            </div>
                            <div className="">
                                <h5 className="highlight-item-text">Access to <span>107 McGrath Real Estate Offices</span> across the East Coast</h5>
                            </div>
                        </div>
                    </div>

                    <div className="col-11 col-sm-10 col-md-4 mt-4 mt-md-0">
                        <div className="refferal-card highlight-text">
                            <div className="badge-wrapper mb-3">
                                <img className="img-mg-sold" src={McgrathSold} alt="mcgrath-sold" />
                            </div>
                            <div className="">
                                <h5 className="highlight-item-text"><span>14,000+</span> McGrath network property settlements in FY21</h5>
                            </div>
                        </div>
                    </div>

                    <div className="col-11 col-sm-10 col-md-4 mt-4 mt-md-0">
                        <div className="refferal-card highlight-text">
                            <div className="badge-wrapper mb-3">
                                <img className="img-mfaa-logo" src={MfaaLogo} alt="logo-mfaa"/>
                            </div>
                            <div className="">
                                <h5 className="highlight-item-text mfaa-caption-top"><span>MFAA Certified</span><br></br></h5>
                                <h5 className="highlight-item-text mfaa-caption-bottom">All members are certified</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        )
}

export default LargeReferal