import React from "react"
import ach1 from "../../assets/images/careers/awards-carousel/01.png"
import ach2 from "../../assets/images/careers/awards-carousel/02.png"
import ach3 from "../../assets/images/careers/awards-carousel/03.png"
import ach4 from "../../assets/images/careers/awards-carousel/04.png"
import ach5 from "../../assets/images/careers/awards-carousel/05.png"
import ach6 from "../../assets/images/careers/awards-carousel/06.png"
import ach7 from "../../assets/images/careers/awards-carousel/07.png"
import ach8 from "../../assets/images/careers/awards-carousel/08.png"
import ach9 from "../../assets/images/careers/awards-carousel/09.png"
import ach10 from "../../assets/images/careers/awards-carousel/10.png"
import ach11 from "../../assets/images/careers/awards-carousel/11.png"
import ach12 from "../../assets/images/careers/awards-carousel/12.png"
import ach13 from "../../assets/images/careers/awards-carousel/13.png"
import ach14 from "../../assets/images/careers/awards-carousel/14.png"
import ach15 from "../../assets/images/careers/awards-carousel/15.png"
import ach16 from "../../assets/images/careers/awards-carousel/16.png"
import ach17 from "../../assets/images/careers/awards-carousel/17.png"
import ach18 from "../../assets/images/careers/awards-carousel/18.png"
import ach19 from "../../assets/images/careers/awards-carousel/19.png"
import ach20 from "../../assets/images/careers/awards-carousel/20.png"
import ach21 from "../../assets/images/careers/awards-carousel/21.png"
import ach22 from "../../assets/images/careers/awards-carousel/22.png"
import ach23 from "../../assets/images/careers/awards-carousel/23.png"
import ach24 from "../../assets/images/careers/awards-carousel/24.png"
import ach25 from "../../assets/images/careers/awards-carousel/25.png"
import ach26 from "../../assets/images/careers/awards-carousel/26.png"
import ach27 from "../../assets/images/careers/awards-carousel/27.png"
import ach28 from "../../assets/images/careers/awards-carousel/28.png"
import ach29 from "../../assets/images/careers/awards-carousel/29.png"
import ach30 from "../../assets/images/careers/awards-carousel/30.png"
import ach31 from "../../assets/images/careers/awards-carousel/31.png"
import ach32 from "../../assets/images/careers/awards-carousel/32.png"
import ach33 from "../../assets/images/careers/awards-carousel/33.png"
import ach34 from "../../assets/images/careers/awards-carousel/34.png"
import ach35 from "../../assets/images/careers/awards-carousel/35.png"
import ach36 from "../../assets/images/careers/awards-carousel/36.png"
import ach37 from "../../assets/images/careers/awards-carousel/37.png"
import ach38 from "../../assets/images/careers/awards-carousel/38.png"
import ach39 from "../../assets/images/careers/awards-carousel/39.png"
import ach40 from "../../assets/images/careers/awards-carousel/40.png"

import Loadable from "@loadable/component"
const OwlCarousel = Loadable(() => import("react-owl-carousel3"))

const options = {
  loop: true,
  nav: false,
  dots: false,
  autoplayHoverPause: true,
  autoplay: true,
  margin: 15,
  navText: [
    "<i class='flaticon-left-1'></i>",
    "<i class='flaticon-right-1'></i>",
  ],
  responsive: {
    0: {
      items: 3,
    },
    768: {
      items: 4,
    },
    992: {
      items: 5,
    },
  },
}

const Awards = props => {
  const [display, setDisplay] = React.useState(false)

  React.useEffect(() => {
    setDisplay(true)
  }, [])

  return (
    <div className="row awards-area">
      <div className="col">
        <h3 className="h3-award">Award Winning</h3>

        <div className="achievement-icons">
          {display ? (
            <OwlCarousel
              className="awards-carousel owl-carousel owl-theme"
              {...options}
            >
              <div className="item">
                <img src={ach1} alt="AFG Winner - 2016" />
              </div>
              <div className="item">
                <img src={ach2} alt="Top 25 Brokerage - 2016" />
              </div>
              <div className="item">
                <img
                  src={ach3}
                  alt="Australian Broking Awards Finalist - 2016"
                />
              </div>
              <div className="item">
                <img src={ach4} alt="achievement logo" />
              </div>
              <div className="item">
                <img src={ach5} alt="achievement logo" />
              </div>
              <div className="item">
                <img src={ach6} alt="achievement logo" />
              </div>
              <div className="item">
                <img src={ach7} alt="achievement logo" />
              </div>
              <div className="item">
                <img src={ach8} alt="achievement logo" />
              </div>
              <div className="item">
                <img src={ach9} alt="achievement logo" />
              </div>
              <div className="item">
                <img src={ach10} alt="achievement logo" />
              </div>
              <div className="item">
                <img src={ach11} alt="achievement logo" />
              </div>
              <div className="item">
                <img src={ach12} alt="achievement logo" />
              </div>
              <div className="item">
                <img src={ach13} alt="achievement logo" />
              </div>
              <div className="item">
                <img src={ach14} alt="achievement logo" />
              </div>
              <div className="item">
                <img src={ach15} alt="achievement logo" />
              </div>
              <div className="item">
                <img src={ach16} alt="achievement logo" />
              </div>
              <div className="item">
                <img src={ach17} alt="achievement logo" />
              </div>
              <div className="item">
                <img src={ach18} alt="achievement logo" />
              </div>
              <div className="item">
                <img src={ach19} alt="achievement logo" />
              </div>
              <div className="item">
                <img src={ach20} alt="achievement logo" />
              </div>
              <div className="item">
                <img src={ach21} alt="achievement logo" />
              </div>
              <div className="item">
                <img src={ach22} alt="achievement logo" />
              </div>
              <div className="item">
                <img src={ach23} alt="achievement logo" />
              </div>
              <div className="item">
                <img src={ach24} alt="achievement logo" />
              </div>
              <div className="item">
                <img src={ach25} alt="achievement logo" />
              </div>
              <div className="item">
                <img src={ach26} alt="achievement logo" />
              </div>
              <div className="item">
                <img src={ach27} alt="achievement logo" />
              </div>
              <div className="item">
                <img src={ach28} alt="achievement logo" />
              </div>
              <div className="item">
                <img src={ach29} alt="achievement logo" />
              </div>
              <div className="item">
                <img src={ach30} alt="achievement logo" />
              </div>
              <div className="item">
                <img src={ach31} alt="achievement logo" />
              </div>
              <div className="item">
                <img src={ach32} alt="achievement logo" />
              </div>
              <div className="item">
                <img src={ach33} alt="achievement logo" />
              </div>
              <div className="item">
                <img src={ach34} alt="achievement logo" />
              </div>
              <div className="item">
                <img src={ach35} alt="achievement logo" />
              </div>
              <div className="item">
                <img src={ach36} alt="achievement logo" />
              </div>
              <div className="item">
                <img src={ach37} alt="achievement logo" />
              </div>
              <div className="item">
                <img src={ach38} alt="achievement logo" />
              </div>
              <div className="item">
                <img src={ach39} alt="achievement logo" />
              </div>
              <div className="item">
                <img src={ach40} alt="achievement logo" />
              </div>
            </OwlCarousel>
          ) : (
            ""
          )}
        </div>
            <p className="awards-caption"><span>20x</span> Multi Award Winning <span>Brokerage</span></p>

      </div>
    </div>
  )
}

export default Awards
