import React, { useState, useEffect } from "react"
import { Formik, Field, Form, ErrorMessage } from "formik"
import axios from "axios"
import Success from "../Common/FormSuccess"
import Loader from "../Index/Loader"

const CareerContactForm = props => {
  const [SubmitSuccess, setSubmitSuccess] = useState(false)
  const [request, setRequest] = useState(false)
  const [loading, setLoading] = useState(false)
  const [selectedFile, setSelectedFile] = useState()
  const [fileUploadError, setfileUploadError] = useState(false)
  const [fileTypeError, setFileTypeError] = useState(false)

  useEffect(() => {
    if (SubmitSuccess) {
      const timer = setTimeout(() => {
        setSubmitSuccess(false)
      }, 4000)
      return () => clearTimeout(timer)
    }
  }, [SubmitSuccess])

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
  }

  const ValidateEmail = value => {
    let error
    if (!value) {
      return (error = "Required")
    }
    const RegEx =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (!RegEx.test(value)) {
      error = "Invalid Email"
    }
    return error
  }

  const ValidateName = value => {
    let error
    if (!value) {
      return (error = "Required")
    }
    const RegEx = /^[a-zA-Z ]{2,30}$/
    if (!RegEx.test(value)) {
      error = "Invalid Name"
    }
    return error
  }

  const handleFileUplaod = event => {
    if (event.target.files[0]) {
      const size = event.target.files[0].size / (1024 * 1024)
      const acceptedTypes = [
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ]
      if (!acceptedTypes.includes(event.target.files[0].type)) {
        console.log("file type issue")
        setFileTypeError(true)
        setSelectedFile(undefined)
        return
      } else {
        setFileTypeError(false)
      }
      if (size > 3) setfileUploadError(true)
      else {
        setfileUploadError(false)
        setSelectedFile(event.target.files[0])
      }
      event.target.value = ""
    }
  }

  const ValidatePhone = value => {
    let error
    if (value.length == 12) {
      if (!value.startsWith("+")) {
        return (error = "Invalid Phone Number")
      } else {
        value = value.slice(1)
        return (error = !/^\d{11}$/.test(value) ? "Invalid Phone Number" : "")
      }
    } else if (value.length == 10) {
      const RegEx = /^\d{10}$/
      if (!RegEx.test(value)) {
        error = "Invalid Phone Number"
      }
    } else {
      return (error = "Invalid Phone Number")
    }
    return error
  }

  const ValidateMessage = value => {
    let error
    if (value.length > 2000) {
      return (error = "Over the 2000 character limit in the current line")
    }
  }

  const onSubmit = async (values, { resetForm }) => {
    if (props.Broker) {
      values.agentId = props.Broker.id
      values.agentEmail = props.Broker.email
      values.agentName = props.Broker.firstName
    }
    if (!request) {
      try {
        setRequest(true)
        setLoading(true)
        var formData = new FormData()
        const params = {
          FirstName: values.firstName,
          LastName: values.lastName,
          Email: values.email,
          Phone: values.phone,
          Message: values.message,
        }

        formData.append("attributes", JSON.stringify(params))
        formData.append("file", selectedFile)

        console.log("formdata", formData)
        const res = await axios.post(
          `${process.env.GATSBY_BACKEND_API_URL}/appointments/careers`,
          formData,
          {}
        )
        setSubmitSuccess(true)
        setfileUploadError(false)
        resetForm(initialValues)
        setFileTypeError(false)
        setSelectedFile(undefined)
        if (window.pageYOffset !== 0) {
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
        }
        setRequest(false)
        setLoading(false)
      } catch (error) {
        console.log("upload error", error)
        setSubmitSuccess(false)
        setLoading(false)
      }
    }
  }

  return (
    <section className="contact-area">
      {loading ? <Loader /> : null}
      {SubmitSuccess ? (
        <Success subTitle={"You application has been received"} />
      ) : null}
      <div className="contact-form">
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
          <Form id="contactForm">
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <div className="form-group">
                  <label>First Name</label>
                  <Field
                    type="text"
                    id="firstName"
                    name="firstName"
                    className="form-control"
                    placeholder="Your first name"
                    validate={ValidateName}
                  />
                  <ErrorMessage name="firstName">
                    {msg => <div style={{ color: "red" }}>{msg}</div>}
                  </ErrorMessage>
                </div>
              </div>

              <div className="col-lg-6 col-md-12">
                <div className="form-group">
                  <label>Last Name</label>
                  <Field
                    type="text"
                    id="lastName"
                    name="lastName"
                    className="form-control"
                    placeholder="Your last name"
                    validate={ValidateName}
                  />
                  <ErrorMessage name="lastName">
                    {msg => <div style={{ color: "red" }}>{msg}</div>}
                  </ErrorMessage>
                </div>
              </div>

              <div className="col-lg-6 col-md-12">
                <div className="form-group">
                  <label>Mobile Phone</label>
                  <Field
                    type="tel"
                    id="phone"
                    name="phone"
                    className="form-control"
                    placeholder="Your mobile number"
                    validate={ValidatePhone}
                  />
                  <ErrorMessage name="phone">
                    {msg => <div style={{ color: "red" }}>{msg}</div>}
                  </ErrorMessage>
                </div>
              </div>

              <div className="col-lg-6 col-md-12">
                <div className="form-group">
                  <label>E-mail</label>
                  <Field
                    name="email"
                    id="email"
                    className="form-control"
                    placeholder="you@example.com"
                    validate={ValidateEmail}
                  />
                  <ErrorMessage name="email">
                    {msg => <div style={{ color: "red" }}>{msg}</div>}
                  </ErrorMessage>
                </div>
              </div>

              <div className="col-lg-12 col-md-12">
                <div className="form-group">
                  <label>Message</label>
                  <Field
                    as="textarea"
                    name="message"
                    id="message"
                    className="form-control"
                    placeholder="Tell us why you're the ideal candidate"
                    rows="5"
                    validate={ValidateMessage}
                  />
                  <ErrorMessage name="message">
                    {msg => <div style={{ color: "red" }}>{msg}</div>}
                  </ErrorMessage>
                </div>
              </div>

              {/* <input type="file" name="file" /> */}

              <div className="col-lg-12 col-md-12 mb-2">
                <div className="form-group d-flex flex-column">
                  <label>
                    Upload Your Resume (Optional){" "}
                    <span className="small-print">(PDF,DOCX or DOC Only)</span>
                  </label>
                  {/* <input type="file" name="file" /> */}
                  <div class="upload-btn-wrapper">
                    <button class="btn btn-upload">Choose File</button>
                    <div>
                      <input
                        id="file"
                        className="file-input"
                        title=" "
                        name="file"
                        accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                        type="file"
                        onChange={handleFileUplaod}
                      />
                      {selectedFile !== undefined &&
                      fileUploadError == false ? (
                        <p className="file-name">{selectedFile.name}</p>
                      ) : null}
                      {fileUploadError ? (
                        <p className="file-name file-upload-error">
                          Sorry, the upload limit is 3MB
                        </p>
                      ) : null}
                      {fileTypeError ? (
                        <p className="file-name file-upload-error">
                          Sorry, only PDF,DOCX or DOC file types are accepted
                        </p>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-12 col-md-12">
                <button
                  type="submit"
                  className="default-btn primary-btn disabled"
                  // disabled={selectedFile === undefined}
                >
                  Send Message <span></span>
                </button>
              </div>
            </div>
          </Form>
        </Formik>
      </div>
    </section>
  )
}

export default CareerContactForm
