import React, { useState, useEffect } from "react"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import Banner from "../components/Careers/Banner"
import Awards from "../components/Careers/Awards"
import LargeReferal from "../components/Careers/LargeReferal"
import DigitalGrowth from "../components/Careers/DigitalGrowth"
import Footer from "../components/App/Footer"
import ContactInfo from "../components/Contact/ContactInfo"
import CareerContactForm from "../components/Careers/CareerContactForm"
import axios from "axios"
import SEO from "../components/App/SEO"

const Careers = () => {
  return (
    <Layout>
      <SEO
        title="Careers | Oxygen - Home Loans Made Simple"
        description="Want to Become a part of Our Elite Broker Network?"
      />
      <Navbar />
      <Banner />

      {/* Awards section  */}
      <section>
        <div className="awards-section pt-5 pb-5">
          <div className="container">
            <Awards />
            {/* <div className="row">
              <div className="col-12">

              </div>
            </div> */}
          </div>
        </div>
      </section>

      {/* large Referrals section  */}
      <section>
        <div className="large-referral-section pt-5 pb-5">
          <LargeReferal />
        </div>
      </section>

      {/* Digital innovation  */}
      <section>
        <div className="digital-growth-section pt-5 pb-5">
          <DigitalGrowth />
        </div>
      </section>

      {/* Opportunities for all levels  */}
      <section>
        <div className="container">
          <div className="row">
            <div className="col-12 pt-5 pb-4">
              <h3 className="h3-careers">
                Opportunities for all experience levels
              </h3>
              <p>
                Whether you are new to mortgage broking or have 20 years of
                experience, we pride ourselves in making it an easy and simple
                transition to Oxygen.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Application Form  */}
      <section id="careers-form">
        <div className="container pb-100 pt-100">
          <div className="pb-2 pb-md-0 pb-lg-4">
            <h3 className="h3-careers">Apply Now!</h3>
          </div>
          <div className="row">
            <div className="col-lg-8 col-12 pr-lg-5">
              <CareerContactForm />
            </div>
            <div className="col-lg-4 col-12">
              <ContactInfo />
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </Layout>
  )
}

export default Careers
