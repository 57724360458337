import React from "react"

import Eghteen from "../../assets/images/careers/icon-digital-innovation.svg"

const DigitalGrowth = () => {
    return (

        <div className="container">
        <div className="row d-flex justify-content-center">

            <div className="col-11 col-sm-12">
                <div className="digital-growth-card highlight-text">
                    <div className="">
                        <img src={Eghteen} className="" alt="18-years" />
                        <h3 className="digital-growth-text">Focus on <span>Rapid Digital Innovation</span> and <br></br>Hyper-Growth</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
        )
    }
    
    export default DigitalGrowth
